import { h, render, Component } from "preact";
import { Client, LobbyClient } from 'boardgame.io/client';
import { SocketIO } from 'boardgame.io/multiplayer'
import { LoveLetter, CARDS, GUARD } from './game';
import { Route } from "wouter-preact";

const SERVER = "";

function isActive(ctx, playerID) {
    return ctx.activePlayers === null ? isTurn(ctx, playerID) : playerID in ctx.activePlayers;
}

function isTurn(ctx, playerID) {
    return ctx.currentPlayer == playerID;
}

function activeStage(ctx, playerID) {
    return ctx.activePlayers && playerID in ctx.activePlayers ? ctx.activePlayers[playerID] : null;
}

function currentStage(ctx) {
    const cur = ctx.currentPLayer;
    return ctx.activePlayers[cur];
}

function lastTurn(log) {
    const entry = log.findLast(entry => entry.action.type == "MAKE_MOVE" && 'metadata' in entry);
    return entry && {
        playerID: entry.action.payload.playerID,
        ...entry.metadata,
    };
}

function describeMove(move, players, me) {
    return (move.playerID == me ? "You" : players[move.playerID].name)
        + " played " + CARDS[move.played].name
        + (move.target ? " on " + (move.target == me ? "you" : players[move.target].name) : "")
        + (move.guess ? ", guessing " + CARDS[move.guess].name : "");
}

class App extends Component {
    constructor() {
        super();
        this.lobby = new LobbyClient({server: SERVER});
        this.game = LoveLetter.name;
    }
    render() {
        return h("div", null,
            h(Route, {path: "/"}, () => h(CreateMatch, {lobby: this.lobby, game: this.game})),
            h(Route, {path: "/:matchID"}, props => h(MatchLoader, {lobby: this.lobby, game: this.game, ...props})),
        );
    }
}

class CreateMatch extends Component {
    constructor({lobby, game}) {
        super();
        this.lobby = lobby;
        this.game = game;
        this.setState({
            players: 2,
        });
    }
    createMatch(players) {
        this.lobby.createMatch(this.game, {numPlayers: players, unlisted: true, setupData: {}})
            .then(({matchID}) => window.location = '/' + matchID);
    }
    render() {
        return h("div", {className: "page"}, [
            h("div", {className: "logo"}),
            h("div", {className: "form"},
                h("select", {onChange: e => this.setState({players: parseInt(e.target.value, 10)})}, [2, 3, 4, 5, 6,].map(i => h("option", {value: i}, i, " players"))),
                h("button", {type: "button", onClick: () => this.createMatch(this.state.players)}, "New Game")),
        ]);
    }
}

class MatchLoader extends Component {
    constructor({lobby, game, matchID, playerID, credentials}) {
        super();
        this.lobby = lobby;
        this.game = game;
        this.matchID = matchID;

        if (!credentials && localStorage.getItem(matchID)) {
            const cred = JSON.parse(localStorage.getItem(matchID));
            playerID = cred.playerID;
            credentials = cred.credentials;
        }

        this.state = {
            loaded: false,
            match: null,
            name: "",
            playerID: playerID,
            credentials: credentials,
        };
    }
    componentDidMount() {
        this.loadMatch();
    }
    loadMatch() {
        return this.lobby.getMatch(this.game, this.matchID)
            .then(match => this.setState({loaded: true, match: match}))
            // XXX handle errors
            .catch(() => this.setState({loaded: true}));
    }
    joinMatch() {
        this.lobby.joinMatch(this.game, this.matchID, {playerName: this.state.name})
            .then(({playerID, playerCredentials}) => {
                const cred = {playerID, credentials: playerCredentials};
                localStorage.setItem(this.matchID, JSON.stringify(cred));
                this.setState(cred);
            })
            // XXX handle errors
            .catch(() => {});
    }
    leaveMatch() {
        this.lobby.leaveMatch(this.game, this.matchID, {
                playerID: this.state.playerID,
                credentials: this.state.credentials
            })
            .then(() => {
                localStorage.removeItem(this.matchID);
                this.setState({playerID: null, credentials: null});
                return this.loadMatch();
            });
    }
    render() {
        const {loaded, match, playerID, credentials} = this.state;
        if (!loaded) {
            return h("div", {}, "Loading...");
        }
        if (!match) {
            return h("div", {}, "An error occurred.");
        }

        const open = match.players.filter(p => !p.name).length;
        if (open > 0 && !credentials) {
            return h("div", {className: "page"},
                h("div", {className: "logo"}),
                h("h1", {}, open, " left"),
                h("form", {className: "form", onSubmit: e => { this.joinMatch(); e.preventDefault(); }},
                    h("input", {type: "text", maxLength: 25, value: this.state.name, placeholder: "Name", onChange: e => this.setState({name: e.target.value})}),
                    h("button", {type: "button", onClick: () => this.joinMatch()}, "Join"),
                ));
        }

        // credentials may be blank, but then they're a spectator
		const client = Client({
            game: LoveLetter,
            // numPlayers: 4,
            matchID: this.matchID,
            playerID: playerID,
            credentials: credentials,
            multiplayer: SocketIO({server: SERVER}),
            // debug: false,
        });
        return h(MatchClient, {client});
    }
}

class MatchClient extends Component {
    constructor({client}) {
        super();
		this.client = client;
        this.setState({
            targeting: 0,
            playing: 0,
            guessing: 0,
        });
    }
    componentDidMount() {
        this.unsubscribe = this.client.subscribe(() => this.forceUpdate());
        this.client.start();
    }
    componentWillUnmount() {
        this.client.stop();
        this.unsubscribe();
    }
    playerName(playerID) {
        return this.client.matchData.find(p => p.id == playerID)?.name;
    }
    render() {
        const state = this.client.getState();
        if (!state) {
            return h("div", null, "Loading...");
        }

        // are we an active player?
        const {ctx, G} = state;
        const stage = activeStage(ctx, this.client.playerID);
        const last = lastTurn(state.log);

        // fill in player data if we're in local, boo
        const players = this.client.matchData ?? [...Array(ctx.numPlayers)].map((n, i) => ({id: i, name: "Player "+i})),
            me = this.client.playerID ? G.players[this.client.playerID] : null,
            myTurn = ctx.currentPlayer === this.client.playerID,
            curPlayerName = players[ctx.currentPlayer].name,
            currentStage = ctx.activePlayers && ctx.activePlayers[ctx.currentPlayer];

        // blank player name means nobody has joined yet
        const open = players.filter(p => !p.name).length;
        if (open > 0) {
            const join = new URL("/" + this.client.matchID, window.location);
            return h("div", {className: "page"},
                h("div", {className: "logo"}),
                h("h1", {}, "Waiting for ", open, " more..."),
                h("h2", {}, "Invite someone:"),
                h("div", {className: "form"},
                    h("input", {className: "join-link", type: "text", value: join.toString(), onFocus: e => e.target.select()})),
            );
        }

        if (ctx.phase == "winner") {
            const winners = G.gameover;
            return h("div", {className: "board"}, [
                h(Deck, {count: G.remaining, revealed: G.revealed}),
                h(PlayerList, {players, G, onClick: () => {}}),
                h("div", {className: "middle"}, [
                    h("h1", {}, "Game over!"),
                    h("h2", {}, (winners.length == 1 ? "Winner: " : "Winners: ") + winners.map(([id, score]) => players[id].name + " (" + score + ")").join(', ')),
                ]),
                myTurn ? h("div", {className: "bottom"}, [
                    h("button", {className: "button", onClick: () => this.client.moves.reset()}, "RESET"),
                ]) : "",
            ]);
        }

        const chooseCard = (card) => {
            (stage === "return") ? this.client.moves.return(card) : this.client.moves.discard(card);
        };

        const cur = (myTurn ? "You are " : curPlayerName + " is ") + (() => {
            if (ctx.phase == "wait") {
                return "slacking";
            }
            switch (currentStage) {
            case "discard":
                return "picking a card...";
            case "return":
                return "returning cards...";
            case "targetAny":
            case "target":
                return "choosing a target...";
            case "guess":
                return "guessing...";
            case "look":
                return "looking at " + (G.target === this.client.playerID ? "your" : players[G.target].name + "'s") + " card";
            }
        })();

        return h("div", {className: "board"}, [
            h(Deck, {count: G.remaining, revealed: G.revealed}),

            h(PlayerList, {players, G, onClick: (player) => this.client.moves.choose(player)}),

            h("div", {className: "middle"}, [
                h("h2", {}, last ? describeMove(last, players, this.client.playerID) : ""),
                h("h1", {}, cur)
            ]),

            // guessing cards are shown for all players
            currentStage == "guess" ? h(ChooseCard, {selected: G.guess, onClick: (card) => this.client.moves.guess(card)}) : null,

            // obviously only for us
            stage == "look" ? h("div", {className: "look"}, h(Card, {card: me.look, showName: true})) : null,

            // do not show in wait or game-end spectators will not have a hand
            me ? h("div", {className: "hand"}, me.cards.map(card => h(Card, {card, showText: true, showName: true, onClick: chooseCard}))) : null,

            stage == "look" || stage == "target" || stage == "targetAny" || stage == "guess" ? [
                h("div", {className: "bottom"}, [
                    h("button", {className: "button", onClick: () => this.client.moves.done()}, "OK"),
                ]),
            ] : null,

            ctx.phase == "wait"  && myTurn ? [
                h("div", {className: "bottom"}, [
                    h("button", {className: "button", onClick: () => this.client.moves.ready()}, "READY"),
                ]),
            ] : null,
        ]);
    }
}

function Deck({count, revealed}) {
    return h("div", {className: "top"}, [
        h("div", {className: "deck card small"}, count),
        h("div", {className: "logo abscenter"}),
        h(PlayedCardList, {cards: revealed}),
    ]);
}

function PlayerList({players, G, onClick}) {
    const {active, played, score, target} = G;
    const prot = G.protected;
    return h("div", {className: "playerList"},
        players.map(p => {
            // id is stored as a number, but should be a string... dumb
            const id = ""+p.id,
                isActive = active && active.indexOf(id) !== -1,
                isProtected = prot && prot.indexOf(id) !== -1;
            return h("div", {className: "player" + (isActive ? " alive" : " dead") + (target == id ? " targeted" : "") + (isProtected ? " protected" : ""), onClick: () => onClick(id)}, [
                h("div", {className: "playerName"}, (p.name??""), Array(score[id]).fill(h("span", {className: "pip"}))),
                h(PlayedCardList, {cards: played[id]})
            ]);
        })
    );
}

function PlayedCardList({cards}) {
    if (!cards.length) {
        // empty hand
        return h("div", {className: "played"}, h("div", {className: "card small"}));
    }
    return h("div", {className: "played"}, cards.map((value) => {
        return h("div", {className: "card small " + CARDS[value].name.toLowerCase()}, value);
    }));
}

function Card({card, onClick, showName, showText, className}) {
    const {name, text} = CARDS[card];
    return h("div", {className: ["card", className, name.toLowerCase()].join(" "), onClick: () => onClick(card)}, [
        h("div", {className: "cardValue"}, card),
        showName === true ? h("div", {className: "cardName"}, name) : null,
        showText === true ? h("div", {class: "cardText"}, text) : null
    ]);
}

function ChooseCard({selected, onClick}) {
    return h("div", {class: "chooseCard"},
        Object.keys(CARDS).filter(c => CARDS[c].guessable).map(card => {
            return h(Card, {className: (selected == card) ? "selected" : "", showName: true, card, onClick});
        })
    );
}

function ChoosePlayer({players, selected, onSelect, onDone}) {
    return h("div", {class: "guess"},
        h("h1", {}, "Choose a player"),
        h("div", {className: "choose"}, players.map(p => {
            const id = ""+p.id;
            return h("div", {classname: (selected == id) ? "selected" : "", onClick: () => onSelect(id)}, p.name);
        })),
        h("div", {}, h("button", {className: "input button", onClick: () => onDone()}, "Done")),
    );
}

function Scoreboard({players, scores}) {
    return h("div", {class: "scores"}, [
    ]);
}

render(h(App), document.body);

// const client = {
//     matchData: [
//         {id: 0, name: "bob"},
//         {id: 1, name: "joe"},
//         {id: 2, name: "bill"},
//         {id: 3, name: "frank"},
//         {id: 4, name: "harry"},
//         {id: 5, name: "jill"},
//     ],
//     playerID: "0",
//     getState: function() {
//         return {
//             G: {
//                 playing: 1,
//                 target: 2,
//                 guess: null,
//                 winners: null,
//                 remaining: 4,
//                 score: {
//                     "0": 0,
//                     "1": 0,
//                     "2": 0,
//                     "3": 0,
//                     "4": 0,
//                     "5": 0,
//                 },
//                 active: ["0", "1", "3", "5"],
//                 protected: ["3"],
//                 played: {
//                     "0": [1,2,3,4,5,6,7,8,9,0],
//                     "1": [1,2,3,4,5,6,7,8,9,0],
//                     "2": [1,2,3,4,5,6,7,8,9,0],
//                     "3": [1,2,3,4,5,6,7,8,9,0],
//                     "4": [1,2,3,4,5,6,7,8,9,0],
//                     "5": [1,2,3,4,5,6,7,8,9,0],
//                 },
//                 revealed: [1,2,3],
//                 players: {
//                     "0": {
//                         cards: [1,0,3],
//                         look: null,
//                     },
//                 },
//             },
//             ctx: {
//                 currentPlayer: "0",
//                 numPlayers: 6,
//                 activePlayers: {
//                     "0": "target",
//                 }
//             },
//             log: [{action:{type:"MAKE_MOVE",payload:{playerID:"0"}},metadata:{played:1,guess:5,target:"2"}}],
//         };
//     },
//     subscribe: function(cb) {
//     },
//     start: function() {
//     },
//     stop: function() {
//     },
// };
// render(h(MatchClient, {client}), document.body);
